import React, { useState } from "react";
import { DatePicker, Form, Input, Select } from "antd";
import PhoneInput from "react-phone-input-2";
import type { DatePickerProps } from "antd";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
const { Option } = Select;
const dateFormat = "YYYY/MM/DD";
const PersonalDataForm = (props: any) => {
  const { wareHouse } = useSelector((state: any) => state);
  const [selectedCountry, setSelectedCountry] = useState("250");
  const handleCountryChange = (country: any) => {
    setSelectedCountry(country);
  };
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date, dateString);
    props?.setSelectedDate(dateString);
  };

  const todaysDate = new Date().toISOString().slice(0, 10);
  const dateofbirth = wareHouse?.createdCart?.data?.customer?.dateOfBirth;

  return (
    <div className=" rounded-lg">
      <div className="grid md:grid-cols-3 gap-x-4 pt-10">
        <Form.Item
          name="phone"
          label={
            <span className="text-[#0F0F47] text-sm">
              <span className="text-red-500">*</span> Phone Number
            </span>
          }
          initialValue={props?.dataToUpdate?.phone}
          rules={[
            {
              validator: (_, value) => {
                const phoneNumberLength = value
                  ? value.replace(/\D/g, "").length
                  : 0;
                if (selectedCountry?.slice(0, 3) === "250") {
                  if (phoneNumberLength === 12) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Phone Number should be exactly 9 Digits.")
                  );
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <PhoneInput
            onChange={handleCountryChange}
            country={"rw"}
            buttonStyle={{
              height: "50px",
              border: "1px solid #E5E7EB",
            }}
            inputStyle={{
              height: "50px",
              width: "100%",
              border: "1px solid #E5E7EB",
            }}
            containerClass="phone-container"
            placeholder="+250 780 000 000"
          />
        </Form.Item>
        <Form.Item
          name="nationalId"
          label={<span className="text-[#0F0F47] text-sm">National ID</span>}
          rules={[
            {
              required: true,
              message: "National ID is required!",
              whitespace: true,
            },
          ]}
          getValueFromEvent={(e) =>
            e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
          }
        >
          <Input
            className="pl-4 border"
            placeholder="National Id"
            style={{ height: 50 }}
          />
        </Form.Item>
        <Form.Item
          name="firstName"
          label={<span className="text-[#0F0F47] text-sm">First Name</span>}
          rules={[
            {
              required: true,
              message: "First Name is required!",
              whitespace: true,
            },
          ]}
          getValueFromEvent={(e) =>
            e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
          }
        >
          <Input
            className="pl-4 border"
            placeholder="First Name"
            style={{ height: 50 }}
          />
        </Form.Item>
      </div>
      <div className="grid md:grid-cols-3 gap-x-4">
        <Form.Item
          name="lastName"
          className="w-[100%]"
          label={<span className="text-[#0F0F47] text-sm">Last Name</span>}
          // initialValue={props?.dataToUpdate?.name
          //   ?.slice(firstNameToUpdate?.length)
          //   ?.trim()}
          rules={[
            {
              required: true,
              message: "Last Name is required!",
              whitespace: true,
            },
          ]}
          getValueFromEvent={(e) =>
            e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
          }
        >
          <Input
            className="pl-4 border"
            placeholder="Last Name"
            style={{ height: 50 }}
          />
        </Form.Item>
        <Form.Item
          name="email"
          className="w-[100%]"
          label={<span className="text-[#0F0F47] text-sm">Email</span>}
          initialValue={props?.dataToUpdate?.email}
        >
          <Input
            className="pl-4 border"
            placeholder="Email"
            style={{ height: 50 }}
          />
        </Form.Item>
        <Form.Item
          className="w-full md:w-[90%]d"
          name="gender"
          label={<span className="text-[#0F0F47] text-sm">Gender</span>}
          rules={[
            {
              required: true,
              message: "Gender is required!",
              whitespace: true,
            },
          ]}
        >
          <Select className="h-12">
            {["male", "female"].map((el: any) => (
              <Option key={el} className="capitalize">
                {el}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className="grid md:grid-cols-3 gap-x-4">
        <Form.Item
          name="dateBirth"
          className="w-[100%]"
          label={<span className="text-[#0F0F47] text-sm">Date of Birth</span>}
          // rules={[
          //   {
          //     required: true,
          //     message: "Date of birth is required!",
          //     whitespace: true,
          //   },
          // ]}
        >
          <DatePicker
            className="w-[100%] h-[50px] md:h-[50px]"
            onChange={onChange}
            defaultValue={dayjs(
              dateofbirth === undefined ? todaysDate : dateofbirth,
              dateFormat
            )}
          />
        </Form.Item>
        <Form.Item
          className="w-full md:w-[90%]f"
          style={{ border: "none" }}
          name="maritalStatus"
          label={<span className="text-[#0F0F47] text-sm">Martial Status</span>}
          rules={[
            {
              required: true,
              message: "Marital Status is required!",
              whitespace: true,
            },
          ]}
        >
          <Select className="border-none h-12 md:h-12">
            {["single", "married", "divorced", "widowed", "separated"].map(
              (el: any) => (
                <Option key={el} className="capitalize">
                  {el}
                </Option>
              )
            )}
          </Select>
        </Form.Item>
      </div>
    </div>
  );
};
export default PersonalDataForm;
